import {Box, Button, Flex, Spacer, IconButton,Slider,
  SliderTrack,
  SliderFilledTrack,Center,
  SliderThumb,} from "@chakra-ui/react";
import { FaPlay, FaPause, FaStop, FaCircle} from 'react-icons/fa';
import React from 'react';
import {Howl, Howler} from 'howler';

const RadioPlayer = () =>{
    Howler.autoUnlock = false
    Howler.ctx = true
    var id
    const sound = new Howl({
      src: ['http://stream.zeno.fm/rfp60f7y868uv'],
      html5: true,
      format: ['mp3', 'aac'],
      autoplay: false,
      onplayerror: ()=>{refreshPage()},
      onloaderror: ()=>{refreshPage()},
    });

  function play() {
    if(!sound.playing()  || !(sound.state() === "loaded")){
      if(id){
        sound.play(id)
      }
      else{
        id = sound.play()
      }
    }
  }

  function stop(){
    if(id){
      sound.stop(id)
    }
  }

  function pause(){
    if(id){
      sound.pause(id)
    }
  }
  
  function reiniciar() {
    if(id){
      if(sound.playing(id)){
        sound.stop(id)
        play()
      }
    }else{
      play()
    }
  }
 
  function cambiarVolumen(vol){
    if(id){
     sound.volume(vol/100.0, id)
    }else{
      sound.volume(vol/100.0)
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }
  return(
        <Flex width="100%" flexDirection="row">
            <Flex flex="2" justify="center">
            <Center  w="100%">
                <Box  flex="0.8">
                  <IconButton onClick={play} padding="20%"  style={{outline: '0', boxShadow: 'none'}} colorScheme="orange" fontSize="1.5em" icon={<FaPlay/>}/><Spacer/>
                </Box>
                <Spacer/>
                <Box flex="0.8">
                  <IconButton onClick={pause} style={{outline: '0', boxShadow: 'none'}} padding="20%" colorScheme="orange" fontSize="1.5em" icon={<FaPause/>}/><Spacer/>
                </Box>
                <Spacer/>
                <Box flex="0.8">
                  <IconButton padding="20%"  style={{outline: '0', boxShadow: 'none'}} onClick={stop} colorScheme="orange" fontSize="1.5em" icon={<FaStop/>}/><Spacer/>
                </Box>
                <Spacer/>
                <Box flex="0.6">
                  <Button variant="outline" style={{outline: '0', boxShadow: 'none'}}  color="red" onClick={reiniciar} leftIcon={<FaCircle/>}>Vivo</Button>
                </Box>
                <Spacer/>
              </Center>
            </Flex>
            
            <Flex flex="1">
                <Slider orientation={['vertical','vertical','vertical']} defaultValue={100} onChange={(val) => cambiarVolumen(val)}>
                    <SliderTrack bg="red.100">
                    <SliderFilledTrack bg="tomato" />
                    </SliderTrack>
                    <SliderThumb/>
                </Slider>
            </Flex>
        </Flex>
  )}
export default RadioPlayer;