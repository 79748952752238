import './App.css';
import RadioPlayer from './Componentes/RadioPlayer.js';
import Presentacion from './Componentes/Presentacion.js';
import {Text,
  Wrap, WrapItem,
  ChakraProvider} from "@chakra-ui/react";
import SocialMedia from './Componentes/SocialMedia';
function App() {
  
  return (
      <div className="App">
        <header className="App-header">
          <ChakraProvider>
            <Wrap direction="column" w={["95%","95%","80%"]} >
              <WrapItem>
                <Text mb={['6%','6%','8%']} align="center" w="100%" fontSize={["2xl","2l","5xl"]}>La Estación Radio - FM 96.5</Text>
              </WrapItem>
              <Wrap direction={['column-reverse','column-reverse', 'row']} >
                <WrapItem w="100%" 
                        pos="relative"
                          _before={{
                          content: '""',
                          bgImage:
                            "../radio-fondo.jpg",
                          bgSize: "cover",
                          pos: 'absolute',
                          pointerEvents:'none',
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                          opacity: 0.1
                        }}
                        >
                    
                        <Wrap w={['97%','97%','100%']} direction='row' justify={['center','center','space-between']} >
                          <Presentacion/>
                          <SocialMedia/>
                        </Wrap>
                </WrapItem>
                <WrapItem w="100%" bg="whiteAlpha.300" p="10px" borderRadius="xl">
                  <RadioPlayer/>
                </WrapItem>
              </Wrap>
            </Wrap>
          </ChakraProvider>
        </header>
      </div>
  );
}

export default App;
