import {Text,Grid,GridItem,Link} from "@chakra-ui/react";
import {FaWhatsapp, FaInstagram, FaFacebookF} from 'react-icons/fa';
import { Icon } from "@chakra-ui/react"
const SocialMedia = () => {

    return(
        <Grid w={['100%','100%','50%']} bg="whiteAlpha.300" borderRadius="xl" justifySelf="center">
            <GridItem>
                <Text align="center" w="100%" fontSize={["2xl","4xl"]}>Nuestras redes</Text>
            </GridItem>
            <GridItem  textAlign='left' w='100%' ml='10px' mt='10px'>
                {/* <Link hidden={[false, true, true]} isExternal style={{outline: '0', boxShadow: 'none'}} href="fb://page/734344067319469/">
                    <Icon color="orange" as={FaFacebookF}/>  Seguinos en Facebook
                </Link> */}
                <Link isExternal style={{outline: '0', boxShadow: 'none'}} href="https://www.facebook.com/La-Estaci%C3%B3n-radio-965-734344067319469/">
                    <Icon color="orange" as={FaFacebookF}/>  Seguinos en Facebook
                </Link>
            </GridItem>
            <GridItem textAlign='left' w='100%'  ml='10px' mt='10px'>
                <Link isExternal style={{outline: '0', boxShadow: 'none'}} href="https://instagram.com/laestacionradiofm96.5?utm_medium=copy_link" >
                    <Icon color="orange" as={FaInstagram}/>  Seguinos en Instagram
                </Link>
            </GridItem>
            <GridItem textAlign='left' w='100%'  ml='10px' mt='10px'>
                <Link isExternal style={{outline: '0', boxShadow: 'none'}} href="https://wa.me/543498680119" >
                    <Icon color="orange" as={FaWhatsapp}/>  (03498)15-680119
                </Link>
            </GridItem>
        </Grid>
    )
}
export default SocialMedia